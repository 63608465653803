// Main colors
$primary-color: #2fb3e8;
$secondary-color: white;
$inverted-text-color: #fff;
$title-subscript-color: black;
$light-primary-color: lighten($primary-color, 40%);
$light-primary-border: lighten($primary-color, 20%);

//  cancelable filter styles
$filter-block-background-color: #cecece;

// General styles for whole website
$font-weight: 400;
$header-weight: 600;
$font-color: #333;
$background-color: #fff;
$border-light: #eaeaea;
$header-color: $primary-color;
$border-btn-color: darken($primary-color, 10%);

// Navbar styles
$navbar__background-color: #2fb3e8;
$navbar__text-color: #fff;
$navbar__office-icon-background-color: #2fb3e8;
$navbar__office-icon-background-color--hover: #1d7599;

// Mobile menu styles
$mobile-menu__border-color: #111;
$mobile-menu__background-color: #323130;
$mobile-menu__color: #fff;

$filter-background-color: #f8d0d9;

// Input styles
$input__border-color: #eaeaea;
$input__background-color: #f4f4f4;

// Left navigation styles
$left-nav-background: #fff;
$left-nav__link-color: $font-color;
$left-nav__link-background-color-hover: #c6e3ef;
$left-nav__link-color-hover: $font-color;
$left-nav__link-background-color-active: #c6e3ef;

// account fly-out styles
$account-flyout__background-color: #f4f4f4;
$account-flyout__link-color: $navbar__background-color;
$account-flyout__section-background-color: #fff;
$account-flyout__section-border-color: #dfdfdf;

// summary section styles
$summary-section__text-color: #fff;
$summary-section__header-color: #2fb3e8;
$summary-section__divider-color: #fff;

// main page styles
$command-bar__icon-color: $navbar__background-color;
$main-page__background-color: #f4f4f4;

// Teams styles
$team-wrapper-border: #dfdfdf;
$team-card-icon-color: white;
$team-card-icon-favorite-color: white;

// General breakpoints
$breakpoint__small: "420px";
$breakpoint__medium: "768px";
$breakpoint__big: "1200px";

@import "../imports/imports";
