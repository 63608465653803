.search-bar {
    display: block;
    width: 100%;
    height: 4rem;
    background: $input__background-color;

    .info-label {
        margin: 0;
        border-radius: 0;
    }

    input[type="text"] {
        height: 4rem !important;
        min-height: 4rem !important;
        line-height: 4rem !important;
        background: $input__background-color;
    }

    .ms-TextField-fieldGroup {
        height: 4rem !important;
        min-height: 4rem !important;
        line-height: 4rem !important;
        border: 0.1rem solid $input__border-color;
        border-left: none;
        border-right: none;

        input {
            padding-left: 1.6rem;
        }

        i {
            height: calc(100% - 1.5rem);
            padding-right: 0.9rem;
        }

        @media only screen and (min-width: $breakpoint__small) {
            i {
                padding-right: 1rem;
                padding-top: 0.1rem;
                font-size: 1.6rem;
                font-weight: 600;
            }
        }

        @media only screen and (min-width: $breakpoint__medium) {
            i {
                padding-right: 0.9rem;
                padding-top: 0.1rem;
                font-size: 1.4rem;
            }
        }
    }
}
