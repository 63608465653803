.team-modal-body-wrapper {
    width: 100%;
    padding: 3rem;

    .channel-list-item {
        width: 50%;
        display: inline-block;

        .channel-wrapper {
            width: 100%;
            height: 5.8rem;
            display: inline-flex;
            align-items: center;
            vertical-align: top;
            padding: 0.5rem 0;
        }

        a {
            color: $primary-color !important;
            font-weight: 500;
        }
    }
    .channel-list-item:hover {
        background-color: #eaeaea;
    }

    .fake-link {
        color: $account-flyout__link-color;
        cursor: pointer;
    }

    .team-model-body {
        overflow-y: auto !important;
        &__content {
            margin: 3rem 0;
            text-overflow: ellipsis;
        }
    }

    .plan-wrapper {
        width: 50%;
        height: 4.8rem;
        display: inline-flex;
        align-items: center;
        margin-bottom: 1.5rem;
    }

    .data-is-missing {
        color: red;
        font-weight: bold;
    }

    .plan-image-wrapper {
        flex: 1 1 0;

        &__image {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #2f752d;
            color: white;
            height: 4.8rem;
            width: 4.8rem;
            font-size: 2.4rem;

            & a {
                color: #fff;
            }
        }
    }

    .plan-text-wrapper {
        flex: 4 1 0;
        padding-left: 1.5rem;
        font-size: 1.6rem;
        cursor: pointer;

        & a {
            display: block;
            color: $header-color;
        }
    }

    .member-wrapper {
        width: 100%;
        height: 4.8rem;
        display: inline-flex;
        align-items: center;
        margin-bottom: 2rem;
        vertical-align: top;
    }

    .member-image-wrapper {
        flex: 1 1 0;

        &__image {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: lightgrey;
            color: white;
            height: 4.8rem;
            width: 4.8rem;
            font-size: 2.4rem;
            border-radius: 50%;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            .img-placeholder-big {
                width: 2rem;
                height: 2rem;
                padding-top: 2px;
            }

            & a {
                color: #fff;
            }
        }
    }

    .member-text-wrapper {
        flex: 4 1 0;
        padding-left: 1.5rem;
        font-size: 1.4rem;
        cursor: pointer;

        & a {
            display: block;
            color: $header-color;
        }

        & a:hover {
            text-decoration: none;
        }
    }

    .member-text-job-title {
        font-size: 1.2rem;
        margin-top: 0.5rem;
        display: block;
    }

    .members-wrapper h3 {
        margin-bottom: 1.5rem;
    }

    .members-wrapper h3:not(:first-of-type) {
        margin-top: 3rem;
    }

    .info-rule {
        &__header {
            margin-bottom: 0.5rem;
            font-weight: 600;
        }

        &__list li {
            margin-bottom: 0.5rem;
            text-overflow: ellipsis;
        }

        &__list {
            margin-bottom: 2rem;
        }
    }

    .file-wrapper {
        width: 100%;
        height: 4.8rem;
        display: flex;
        align-items: center;
        border-bottom: 1px solid lightgrey;

        &:hover {
            background-color: #eaeaea;
        }

        &__icon-wrapper {
            flex: 1;
            flex-grow: 1;
            padding: 0 0.5rem;
        }

        &__filename {
            flex: 7;
            text-overflow: ellipsis;
            overflow-x: hidden;
            padding: 5px 0;
            a {
                color: $header-color;
            }
        }

        &__date {
            flex: 2;
        }
    }

    .file-icon {
        font-size: 2rem;
        margin-top: 0.4rem;
    }

    .FileImage {
        // background: linear-gradient(
        // 	to bottom right,
        // 	red,
        // 	orange,
        // 	yellow,
        // 	green,
        // 	cyan,
        // 	blue,
        // 	violet
        // );
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // font-size: 2rem;
        // line-height: 2rem;
        color: orange;
    }

    .WordDocument {
        color: #295598;
    }

    .PowerPointDocument {
        color: #d24725;
    }

    .ExcelDocument {
        color: #1f6e43;
    }

    .Document {
        color: $primary-color;
    }

    .OneNoteLogo {
        color: #813a7c;
    }
}

.info-content-wrapper {
    display: block;
    width: 100%;
}

.info-content-left {
    display: inline-block;
    padding: 0 3rem 0 0;
    width: 100%;
    vertical-align: top;
    line-height: 1.6rem;
    overflow: hidden;

    h2 {
        color: $primary-color;
        font-size: 1.4rem;
        margin-bottom: 1.5rem;
    }
}

.info-content-right {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    line-height: 1.6rem;
    overflow: hidden;

    h2 {
        color: $primary-color;
        font-size: 1.4rem;
        margin-bottom: 1.5rem;
    }
}

@media only screen and (min-width: $breakpoint__medium) {
    .member-wrapper {
        width: 50% !important;

        margin-bottom: 1.5rem;
    }

    .info-content-left {
        width: 50%;
    }

    .info-content-right {
        width: 50%;
    }
}

.custom-alert-text {
    margin-left: 1.6rem;
}
