.commandbar-wrapper  * {
    outline: 0 !important;

    & button  {
        cursor: pointer;
        
    }

    & .ms-Button-icon {
        color: $command-bar__icon-color;
    }
}

.commandbar-wrapper {
    padding-top: .1rem;
    border-bottom: .1rem solid $border-light;
    display: none;
    height: 4rem;
    position: relative;
    width: 100%;

    .ms-CommandBar  {
        // padding-left: 2.7rem;
        margin-left: -1.5rem;
    }

    .ms-OverflowSet-item button {
        padding: 0 1rem;
        color: $command-bar__icon-color;
    }

    .ms-Button-label  {
        margin-top: -.3rem;
    }
}

@media only screen and (min-width: $breakpoint__medium) {  
    .commandbar-wrapper {
        display: block;
    }
}