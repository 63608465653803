.left-navigation-menu {
  display: block;

  h2 {
    font-size: 1.4rem;

    font-weight: 500;
    margin: 4.5rem 1.7rem 1rem 1.7rem;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      display: block;
      color: $primary-color;

      &:hover * {
        background: $left-nav__link-background-color-hover;
        color: $left-nav__link-color-hover;
      }

      a {
        color: $left-nav__link-color;

        & .left-navigation-menu__icon {
          color: $primary-color;
        }

        &:hover {
          text-decoration: none;
        }
      }

      .left-navigation-menu__menu-item-wrapper {
        display: flex;
        width: 100%;
        height: 5rem;
        justify-content: flex-start;
      }

      .left-navigation-menu__icon {
        display: flex;
        width: 5rem;
        height: 5rem;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        margin-left: 0.5rem;
      }

      .left-navigation-menu__text {
        display: flex;
        width: calc(100% - 3rem);
        height: 5rem;
        align-items: center;
        font-size: 1.3rem;
      }
    }
  }
}

.left-nav__active-link {
  background-color: $left-nav__link-background-color-active;
  display: block;
  color: red;
  font-size: 48px;
}
