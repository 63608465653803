.filter-area {
	position: absolute;
	display: block;
	width: 100%;

	background-color: #fff;
	border-bottom: 0.1rem solid $border-light;
	border-top: 0.1rem solid $border-light;
	z-index: 1000;
	padding: 4.6rem;

	.termset-wrapper {
		width: 100%;
		max-height: 21rem;
		margin-right: 2.3rem;
		display: inline-block;
		margin-bottom: 4rem;
		vertical-align: top;

		&__header {
			font-size: 1.4rem;
			font-weight: 500;
			margin-bottom: 1rem;
			height: 2rem;
			display: inline-block;
			width: 100%;
		}

		&__terms-wrapper {
			width: 100%;
            padding-left: 1px;
			max-height: 18rem;
			margin-right: 3rem;
			display: inline-block;
			overflow-y: auto;
			overflow-x: hidden;
		}
	}

	.submit-button {
		display: block;
	}

	@media only screen and (min-width: "822px") {
		.filter-area {
			height: 30rem;
		}

		.termset-wrapper {
			margin-right: 5rem;
			width: 20rem;

			&__terms-wrapper {
				width: 20rem;
			}
		}
	}
}

.filter-cancel-block {
	padding: 0.5rem;
	margin: 0 1.5rem 0rem;
	background-color: $filter-block-background-color;
	color: $inverted-text-color;
	display: inline-block;
	cursor: pointer;

	&__text {
		display: inline-block;
		margin-left: 3rem;
		color: #999;
	}

	&__cancel-button {
		display: inline-block;
	}
}
