.request {
    background: $account-flyout__section-background-color;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid $account-flyout__section-border-color;
    padding: 3rem 4rem 3rem 4rem;

    input,
    select {
        width: 100%;
        display: block;
        padding: 0.4rem 0.8rem 0.6rem;
        font-size: 1.4rem;
    }
    .textarea {
        height: 7rem;
        padding: 0.4rem 0.8rem 0.6rem;
    }

    label {
        width: 100%;
    }

    input:focus,
    input:hover,
    select:focus,
    select:hover {
        outline-color: $primary-color;
    }

    .form-input-group {
        width: 100%;
        display: block;
        margin: 0 0 3rem 0;
    }

    span {
        font-size: 1.4rem;
        display: block;
        margin-bottom: 0.7rem;
    }

    button span {
        margin-bottom: 0.3rem;
    }
    h3 {
        font-size: 1.5rem;
        color: $header-color;
        margin-bottom: 1.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #cecece;
    }

    .spacer-top {
        margin-top: 1.5rem;
    }

    .microsoft-group-check {
        display: block;
        overflow-x: hidden;
        height: 20px;
        margin-top: -25px;
        font-size: 12px;
    }
}

.request-field-label {
    &__text {
        display: inline-block;
    }

    &__required-star {
        display: inline-block !important;
        margin-left: 0.5rem;
        color: red;
    }
}

.request-info-wrapper {
    width: 100%;
    height: 6.8rem;
    display: block;
    margin-top: 1rem;
}

.error-message {
    color: #dc3545;
    font-style: italic;
    margin: 0.1rem 0 0.3rem;
}

.group-error-message {
    color: #dc3545;
}
.group-success-message {
    color: #28a745;
}

.btn-primary {
    background-color: $primary-color;
    border-color: $border-btn-color;
    color: $navbar__text-color;
}
