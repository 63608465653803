.breadcrumb {
	width: 100%;
	height: 5rem;
	display: flex;
	align-items: center;
	padding-left: 3.2rem;
	font-size: 1.2rem;
	background: #e9e9e9;
	border-bottom: 1px solid $team-wrapper-border;
	margin: 0;
	font-family: 'Roboto', sans-serif !important;

	.ms-Breadcrumb {
		margin-top: 0.1rem;
		margin-left: 0;
		font-family: 'Roboto', sans-serif !important;
	}

	.ms-Breadcrumb-itemLink {
		color: $command-bar__icon-color;
		font-size: 1.4rem;
		height: 2.5rem;
		font-family: 'Roboto', sans-serif !important;
	}
	.ms-Breadcrumb-item {
		margin-top: 1rem;
		font-size: 1.4rem;
		height: 2.5rem;
		font-family: 'Roboto', sans-serif !important;
	}
	.itemLink-93:hover {
		background-color: rgb(234, 234, 234);
		text-decoration: underline;
	}

	.ms-Breadcrumb-itemLink[aria-current='page'] {
		color: $font-color;
	}
}
