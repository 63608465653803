.tree-component-wrapper {
    width: 100%;
    margin-top: 3rem;

    .treeview-component-wrapper {
        width: 45%;
        display: inline-block;
        overflow-y: auto;
        height: 450px;
        margin-right: 5%;
    }

    ul,
    .termsets-indent,
    .term-groups-and-children {
        margin-left: 5rem;
    }

    .term-box--tenant {
        padding: 1.2rem 0 1rem 1.5rem;
        width: 100%;
        border: 1px solid lightgrey;
        width: 25rem;
        background-color: #eee;
    }

    .term-box--group-item {
        cursor: default;
        margin: 1rem 0 1rem 0;
        border: 1px solid lightgrey;
        width: 25rem;
        position: relative;
        background-color: #eee;

        &__actions-wrapper {
            &:hover {
                background: $primary-color;
                color: $inverted-text-color;
            }
        }

        .active {
            background: $primary-color;
            color: $inverted-text-color;
        }
    }

    .term-box,
    li {
        margin: 1rem 0 1rem 0;
        border: 1px solid lightgrey;
        width: 25rem;

        cursor: pointer;
        position: relative;
        background-color: #eee;

        &__text-wrapper {
            width: 20.8rem;
            padding: 1.2rem 0 1rem 1.5rem;
            display: inline-block;
            vertical-align: top;
            overflow: hidden;

            &:hover {
                background: #dedede;
            }
            .active {
                background: #dedede !important;
            }
        }

        &__actions-wrapper {
            width: 4rem;
            padding: 1rem 0 1rem 0.4rem;
            background-color: #cecece;
            display: inline-block;
            cursor: pointer !important;
            text-align: center;
            vertical-align: top;

            &:hover {
                background: $primary-color;
                color: $inverted-text-color;
            }
        }

        .active--term-boxes {
            background: #dedede;
        }

        .active {
            background: $primary-color;
            color: $inverted-text-color;
        }

        &__actions-wrapper--dummy {
            width: 4rem;
            padding: 1.1rem 0 1rem 0.4rem;
            display: inline-block;

            text-align: center;
            vertical-align: top;
        }

        &__action-flyout {
            position: absolute;
            top: -0.1rem;
            left: 25.5rem;
            width: 20rem;
            height: 4.3rem;
            z-index: 4000;
            vertical-align: top;

            ul {
                margin: 0;
                vertical-align: top;

                li {
                    border: none;
                    height: 4.3rem;
                    text-align: center;
                    width: 20rem;
                    vertical-align: top;
                    margin: 0;
                    padding: 1.2rem 0;
                    background-color: #cecece;

                    &:hover {
                        background: #aaa;
                    }
                }
            }
        }

        &--full-length {
            width: 25rem;
        }
    }
}

.treeview-details-wrapper {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.treeview-details-content {
    background-color: #e9e9e9;
    border: 0.1rem solid #dfdfdf;
    padding: 4rem;

    h1 {
        font-size: 2rem;
        font-weight: 500;
        margin: 0 0 2rem 0;
        text-align: center;
    }

    .details-form-wrapper {
        width: 100%;
        padding: 0 0 1rem 0;

        .checkbox-spacer {
            margin: 2rem 0 0 0;
        }

        .teams-submit-button {
            margin: 1rem 0 0 0;
            float: right;
        }

        label {
            font-weight: 500;
        }

        .input-feedback {
            margin: 0;
        }

        .form-input-wrapper {
            width: 100%;

            .form-input-description,
            .form-input-field {
                .form-input-group {
                    margin: 0 0 2rem 0;
                }
                width: 50%;
                display: inline-block;

                input {
                    padding: 0.5rem 1rem 0.4rem !important;
                }
                input[type="checkbox"] {
                    width: auto;
                }
            }
        }
    }
}
