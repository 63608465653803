.requests-table-wrapper {
    font-size: 1.4rem;
    max-height: 56rem;
    overflow-y: auto;

    .table {
        tr {
            height: 4rem;
        }

        td {
            height: 4rem;
            width: 20rem;
            padding-left: 2rem;
            background: #ddd;
            border-right: 0.25rem solid $inverted-text-color;
            border-top: 0.25rem solid $inverted-text-color;
            vertical-align: middle;

            &:last-of-type {
                border-right: 0;
            }
        }

        .team-request-table-row {
            &:hover {
                background: #0089be;
            }
        }

        .odd-row {
            td {
                background: #eee;
            }
        }

        th {
            background: $primary-color;
            border-right: 0.25rem solid $inverted-text-color;
            font-weight: 500;
            color: $inverted-text-color;
            vertical-align: middle;
            padding-left: 2rem;
        }

        .request-table-action-column {
            width: 15%;

            .request-table-details-action {
                color: $primary-color;
                // text-decoration: underline;
                cursor: pointer;

                &--action-required {
                    color: darkorange;
                    font-weight: 500;
                    cursor: pointer;
                }
            }
        }
    }
}
.team-request-panel-content-wrapper {
    padding: 3rem 4rem 3rem 4rem;
    background: $account-flyout__section-background-color;
    border-bottom: 1px solid #ccc;
}

.custom-team-request-alert {
    line-height: 2rem;

    button {
        padding: 0.5rem 1rem !important;
    }
}

@media only screen and (max-height: 670px) {
    .requests-table-wrapper {
        height: 33rem;
        max-height: 33rem;
    }
}

@media only screen and (min-height: 670px) AND (max-height: $breakpoint__medium) {
    .requests-table-wrapper {
        height: 35rem;
        max-height: 35rem;
    }
}
