.teaser {
	background-color: $main-page__background-color;
	padding: 5rem;
	width: 100%;

	&__container {
		max-width: 46rem;
		margin: 0 auto;

		img {
			max-width: 100%;
		}
	}

	h1 {
		text-align: center;
		font-size: 3.2rem;
		margin-bottom: 2.2rem;
	}
	p {
		text-align: center;
		font-size: 1.7rem;
		line-height: 2.2rem;
	}
}

.what-is-teams-section {
	background-color: $primary-color;
	color: $inverted-text-color;
	padding: 5rem;
	width: 100%;

	&__right {
		width: 100%;
		padding: 0 2.5rem;
		display: inline-block;
		text-align: center;

		img {
			box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.25);
			max-width: 100%;
			margin-top: 2.5rem;
		}
	}

	&__left {
		width: 100%;
		padding: 0 2.5rem;
		display: inline-block;
		vertical-align: top;

		h2 {
			font-size: 3.4rem;
			color: $inverted-text-color;
			margin-bottom: 2rem;
			text-align: center;
		}

		p {
			font-size: 1.5rem;
			margin-bottom: 1.5rem;
			line-height: 2rem;
		}

		li {
			display: list-item;
			list-style-type: disc !important;
			font-size: 1.5rem;
			line-height: 2rem;

			margin-bottom: 1.5rem;
		}
	}

	&__container {
		max-width: 46rem;
		margin: 0 auto;
	}
}

.why-choose-teams-section {
	background-color: $main-page__background-color;
	padding: 5rem;
	width: 100%;

	&__left {
		width: 100%;
		padding: 0 2.5rem;
		display: inline-block;

		img {
			box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.25);
			max-width: 100%;
			margin-bottom: 3.5rem;
		}
	}

	&__right {
		width: 100%;
		padding: 0 2.5rem;
		display: inline-block;
		vertical-align: top;

		h2 {
			font-size: 3.4rem;

			margin-bottom: 2rem;
		}

		p {
			font-size: 1.5rem;
			margin-bottom: 1.5rem;
			line-height: 2rem;
		}

		li {
			display: list-item;
			list-style-type: disc !important;
			font-size: 1.5rem;
			margin-bottom: 1.5rem;
		}
	}

	&__container {
		max-width: 46rem;
		margin: 0 auto;
	}
}

.free-trial-section {
	background: black;
	display: inline-flex;
	height: 30rem;
	width: 100%;

	&__container {
		height: 100%;
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-content: center;
		margin: 0 auto;
		max-width: 46rem;
		text-align: center;
		padding: 0 2.5rem;

		.teams-button {
			margin-top: 2rem;
			padding: 1rem 2rem;
		}
	}
	h1 {
		color: $inverted-text-color;
		font-weight: 500;
	}
}

.sign-up-form {
	max-width: 32rem;
	margin: 3rem auto 0 auto;
	padding: 5rem;
	font-size: 1.7rem;
	background: #e6e6e6;
	border: 1px solid #d2d2d2;

	input {
		width: 100%;
		display: block;
		margin: 0 0 2rem 0;
		padding: 0.4rem 0.8rem 0.6rem;
		font-size: 1.4rem;
	}
	label {
		font-size: 1.4rem;
		display: block;
		margin-bottom: 0.7rem;
	}
	.input-feedback {
		color: #dc3545;
		font-style: italic;
		margin: 0.1rem 0 0.3rem;
		font-size: 1.2rem;
	}
}

.full-height-page {
	height: auto;
	min-height: 100%;
}

.form-seperator {
	margin-top: 3rem;
	border-top: 0.1rem solid #d2d2d2;
	padding-top: 2rem;
}

@media only screen and (min-width: $breakpoint__medium) {
	.teaser {
		&__container {
			max-width: 66rem;
		}
	}

	.sign-up-form {
		max-width: 66rem;
		padding: 5rem 10rem;
	}

	.what-is-teams-section {
		&__container {
			max-width: 66rem;
		}
	}

	.why-choose-teams-section {
		&__container {
			max-width: 46rem;
		}
	}

	.free-trial-section {
		&__container {
			max-width: 66rem;
		}
	}
}

@media only screen and (min-width: $breakpoint__big) {
	.teaser {
		&__container {
			max-width: 110rem;
		}
	}

	.what-is-teams-section {
		&__container {
			max-width: 110rem;
		}

		&__right {
			width: 50%;
			padding: 0 4rem;

			img {
				margin-top: 0;
			}
		}

		&__left {
			width: 50%;
			padding: 0 4rem;
		}
	}

	.why-choose-teams-section {
		&__container {
			max-width: 110rem;
		}

		&__left {
			width: 50%;
			padding: 0 4rem;
		}

		&__right {
			width: 50%;
			padding: 0 4rem;
		}
	}

	.free-trial-section {
		&__container {
			max-width: 110rem;
		}
	}
}

.app-center {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.sign-up-form__subscription-item {
	margin-bottom: 3rem;

	b {
		display: block;
		margin-bottom: .7rem;
		font-weight: 500;
	}
}