.commandbar-wrapper {
    width: 100%;
    display: flex;
}

.main-page-content-wrapper {
    width: 100%;
    min-height: 100%;
    background: $main-page__background-color;

    display: flex;
    flex-direction: column;

    .page-content {
        max-height: 100%;
        display: flex;
        flex: 1;
        flex-grow: 1;
        flex-direction: column;
    }
}

.no-filters-spacer {
    margin-bottom: 1rem;

    &__all-teams {
        height: 1rem;
    }
}
