:root {
    font-family: "Roboto", sans-serif;
    height: calc(100vh - 4.8rem);
}

h1 {
    font-size: 2rem;
    color: $primary-color;
}

#root {
    height: 100%;
    min-height: 100%;
}

html {
    height: 100%;
    min-height: 100%;
}

body {
    background-color: $background-color;
    font-weight: $font-weight;
    color: $font-color;
    display: block !important;
    height: calc(100vh - 4.8rem);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $header-weight;
    color: $font-color;
}

.siteContainer {
    width: 100%;
    height: calc(100vh - 4.8rem);
}

.mobile-searchbar {
    display: block;
}

.page-container {
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    background: $background-color;
    min-height: 0;

    aside {
        display: none;
        width: 21.3rem;
        background: $left-nav-background;
        display: none;
        border-right: 0.1rem solid $border-light;
    }

    .main {
        display: block;
        flex: 1 0;
        width: calc(100% - 21.3rem);
        height: calc(100vh - 8.8rem);
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
    }

    .team-cards-wrapper {
        margin-left: 0;
        margin: 3.5rem 4rem;
    }

    .filter-summary {
        width: calc(100% - 4rem);
        margin: 0 0 0.2rem 0.6rem;
        padding: 1.5rem;
        font-size: 1.4rem;
        color: $primary-color;

        &__all-teams {
            margin-bottom: calc(2rem + 2px);
        }
    }

    @media only screen and (min-width: $breakpoint__medium) {
        min-height: 100%;

        aside {
            display: block;
        }

        .main {
            height: calc(100vh - 4.8rem);
        }
    }
}

@media only screen and (min-width: $breakpoint__medium) {
    .mobile-searchbar {
        display: none !important;
    }
}

.loaderWrapper {
    display: flex;
    height: 100%;
    min-height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.btn-primary {
    background-color: $primary-color !important;
    color: white;
}

.teams-submit-button {
    padding: 0.1rem 2rem;
    font-size: 1.4rem;
}

.make-a-block {
    display: block;
}

.select__multi-value {
    padding: 0.2rem;
    font-size: 1.4rem;
}

.select__multi-value__remove {
    cursor: pointer;
}

.select__clear-indicator {
    cursor: pointer;
}

.basic-multi-select :active,
.basic-multi-select :focus {
    border-color: $primary-color !important;
}

.btn-outline-primary {
    color: $primary-color !important;
    border-color: $primary-color !important;
}

.App {
    background-color: $main-page__background-color;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle,
.btn-outline-primary:not(:disabled):not(.disabled).hover,
.btn-outline-primary:not(:disabled):not(.disabled):hover,
.show > .btn-outline-primary.dropdown-toggle {
    color: $inverted-text-color !important;
}
