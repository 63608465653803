.team-all-card-wrapper {
    width: calc(100% - 8rem);
    display: block;
    margin: 3.5rem 4rem;
}

.card-more-icon {
    height: 100%;
    width: 3.2rem;
    font-size: 1.4rem;
    float: right;
    margin-left: 8rem;
    padding: 0 1px 0 0;
    align-items: center;
    justify-content: center;
    display: flex !important;
    cursor: pointer;
    background: #dfdfdf;
    border-left: 1px solid #cbcbcb;
    color: #333;
    font-weight: 500;
}

.card-more-actions {
    position: absolute;
    top: 0;
    left: 0;
    height: 7rem;
    width: 11rem;
    background: #dfdfdf;
    text-align: center;
    border-left: 1px solid #cbcbcb;

    &__cancel {
        text-align: right;
        margin: 0.7rem 1rem 0.4rem 0;

        i {
            cursor: pointer;
        }
    }

    ul {
        li {
            width: 100%;
            font-size: 12px;
            padding: 5px 0;
            border-top: 1px solid #cbcbcb;
            cursor: pointer;

            &:hover {
                background-color: $left-nav__link-background-color-hover;
            }
        }

        li:last-of-type {
            border-bottom: 1px solid #cbcbcb;
        }
    }
}

.allteam {
    &-wrapper {
        max-width: calc(100%);
        min-width: calc(100%);
        height: 7rem;
        border: 1px solid $team-wrapper-border;
        display: block;
        background: #fff;
        overflow: hidden;
        margin-bottom: 1.5rem;
    }

    &-logo {
        width: 7rem;
        height: 7rem;
        display: inline-flex;
        background: #d3d3d3;
        color: white;
        cursor: pointer;

        &__center {
            display: inline-flex;
            height: 7rem;
            width: 7rem;
            justify-content: center;
            align-items: center;
        }

        &__image img {
            height: 7rem;
            width: 7rem;
            overflow: hidden;
        }
    }

    &-content {
        width: calc(100% - 18rem);
        height: 100%;
        display: inline-flex;
        vertical-align: top;
        padding: 1.5rem 1.5rem;
        flex-direction: column;
        cursor: pointer;

        h1 {
            font-size: 1.4rem;
            font-weight: 500;
            overflow: hidden;
            margin-right: 1.5rem;

            span {
                font-size: 1.2rem;
                font-weight: normal;
            }
        }

        p {
            margin-top: 1rem;
            font-size: 1.2rem;
            overflow: hidden;
            height: 1.2rem;
        }
    }

    &-actions {
        width: 11rem;
        height: 100%;
        display: inline-flex;
        vertical-align: top;
        flex-direction: column;
        text-align: right;
        position: relative;

        button {
            font-size: 1.2rem;
        }

        .spacer-bottom {
            margin-bottom: 0.5rem;
        }
    }
}

@media only screen and (min-width: $breakpoint__big) {
    .allteam {
        &-wrapper {
            min-width: calc(50%);
            max-width: calc(50%);
            display: inline-block;
            margin-left: 2rem;
        }

        &-wrapper:nth-child(even) {
            min-width: calc(45%);
            max-width: calc(45%);
            margin-left: 2.5%;
        }
    }
}
