.team-card-wrapper__header-photo {
	position: absolute;
	height: 4.8rem;
	width: 4.8rem;
	top: 0.6rem;
	left: 1.5rem;
	overflow: hidden;
	color: white;

	&-alt {
		display: flex;
		width: 4.8rem;
		height: 4.8rem;
		justify-content: center;
		align-items: center;
		font-size: 2.6rem;
	}

	img {
		height: 4.8rem;
		width: 4.8rem;
	}
}
