.wrapper-spacer-team-requests {
    margin-bottom: 3rem;
}

.team-request-wrapper {
    text-align: center;
    display: flex;

    &__action {
        background: #f4f4f4;
        cursor: pointer;
        border-bottom: 4px solid $account-flyout__section-border-color;
        margin-right: 0.5rem;
        width: 100%;
        padding: 1.5rem 0 0.5rem 0;

        &:last-of-type {
            margin-right: 0;
        }

        &:hover {
            background: $primary-color;
            color: $inverted-text-color;
        }

        &--green {
            border-bottom: 4px solid #c3e6cb;

            &:hover {
                background: #c3e6cb;
                color: #155724;
                border-bottom: 4px solid #155724;
            }
        }

        &--darkorange {
            border-bottom: 4px solid #856404;

            &:hover {
                color: #856404;
                background: #fff3cd;
            }
        }

        &--red {
            border-bottom: 4px solid #f5c6cb;

            &:hover {
                background: #f5c6cb;
                color: #721c24;
                border-bottom: 4px solid #721c24;
            }
        }

        &--full-width {
            width: 100%;
        }

        i {
            font-size: 18px;
            margin-bottom: 1rem;
            display: block;
        }

        span {
            font-size: 1.2rem;
        }
    }
}

.team-request-detail-row {
    margin-top: 3rem;
    display: block;

    span {
        line-height: 2rem;
    }

    &__category {
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $account-flyout__section-border-color;

        h2 {
            padding-bottom: 0.5rem;
            font-weight: 500;
        }
    }

    &:first-of-type {
        margin-top: 0;
    }
}

.team-request-detail-item {
    display: flex;
    margin: 0 1rem 2rem 0rem;

    &__header {
        min-width: 12rem;
        display: block;
    }
    &__content {
        padding-right: 1rem;
        min-width: 12rem;
        display: block;
        overflow-wrap: break-word;
    }

    &:last-of-type {
        margin-bottom: 4rem;
    }
}
