.settings-page {
	width: calc(100%);
	height: 100%;
	display: flex;
	background: #f4f4f4;
	flex: 1;
	flex-grow: 1;
	max-height: 100%;
	flex-direction: column;

	& .settings-form-wrapper span {
		font-size: 1.4rem;
		display: block;
		margin-bottom: 0.7rem;
	}

	.form-input-group {
		width: 100%;
		display: block;
		margin: 0 0 3rem 0;
	}

	h3 {
		font-size: 1.5rem;
		color: $header-color;
		margin-bottom: 1.5rem;
		padding-bottom: 0.5rem;
		border-bottom: 1px solid #cecece;
	}

	h3 ~ h3 {
		margin-top: 3rem;
	}

	h3 ~ button {
		margin-top: 3rem;
	}

	input,
	select {
		width: 100%;
		display: block;
		padding: 0.4rem 0.8rem 0.6rem;
		font-size: 1.4rem;
	}
	.textarea {
		height: 7rem;
		padding: 0.4rem 0.8rem 0.6rem;
	}

	label {
		width: 100%;
	}

	label > span {
		font-size: 1.4rem;
		display: block;
		margin-bottom: 0.7rem;
	}

	input:focus,
	input:hover,
	select:focus,
	select:hover {
		outline-color: $primary-color;
	}

	.spacer-top {
		margin-top: 1.5rem;
	}

	.data-is-missing {
		color: red;
		font-weight: bold;
		display: inline-block;
	}

	.submit-button__margin {
		margin-bottom: 0.2rem !important;
	}
	.remove-margin {
		margin: 0 !important;
	}

	.input-feedback {
		color: #dc3545;
		font-style: italic;
		margin: 0.1rem 0 0.3rem;
		font-size: 1.2rem;
	}

	.settings-wrapper {
		display: flex;
		height: 100%;
		flex: 1;
		flex-grow: 1;
		max-height: 100%;
		width: 100%;
		padding: 2rem 4rem;
		flex-direction: column;

		.settings-header {
			width: 100%;
			height: 5rem;
			display: flex;
			align-items: center;
			font-size: 2rem;
			font-weight: 500;
			margin: 0;
		}

		h2 {
			width: 100%;
			display: block;
		}

		.settings-tile-wrapper {
			display: flex;
			flex: 1;
			flex-direction: row;
		}
	}
}

.break {
	flex-basis: 100%;
	height: 0;
}
