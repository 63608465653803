.logout-wrapper {
	width: 4.8rem;
	height: 4.8rem;
	display: inline-block;
	vertical-align: top;

	&__button {
		display: flex;
		flex: 0 0 4.8rem;
		height: 4.8rem;
		width: 4.8rem;
		align-items: center;
		padding: 0;
		margin: 0;
		justify-content: center;
		border: 0;
		outline: 0;
		background-color: $navbar__background-color;
		color: $navbar__text-color;
		font-size: 2rem;
		cursor: pointer;

		&:hover {
			background-color: $navbar__office-icon-background-color--hover;
			text-decoration: none;
		}
	}

	&__avatar {
		height: 2.4rem;
		width: 2.4rem;
		border-radius: 50%;
		margin-top: 0.1rem;
	}

	@media only screen and (min-width: $breakpoint__small) {
		&__avatar {
			height: 2.8rem;
			width: 2.8rem;
		}
	}

	&:active {
		outline: 0;
	}

	&:hover {
		outline: 0;
	}

	&:focus {
		outline: 0;
	}
}

.account-panel > .ms-Panel-main {
	background: $account-flyout__background-color;
}

.account-panel .account-panel .ms-Panel-header {
	display: none;
}

.account-panel .ms-Panel-headerText {
	margin-top: 0;
	font-weight: 500;
	margin: 0 0 15px 0;
}
.account-panel .ms-Panel-commands {
	display: none;
}

.account-panel {
	&__panel-header {
		display: block;
		width: 100%;
		height: 6.4rem;
		background-color: $account-flyout__background-color;

		&--flex {
			display: flex;
			height: 6.4rem;
			width: 100%;
			align-content: center;
		}
	}
	&__panel-close {
		display: flex;
		width: 1.4rem;
		margin-right: 2.5rem;
		height: 6.4rem;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	h1 {
		display: flex;
		width: 100%;
		margin-left: 4rem;
		align-items: center;
	}

	.ms-Panel-content {
		padding: 0;
	}
}

.personal {
	background: $account-flyout__section-background-color;
	border-bottom: 1px solid #ccc;
	border-top: 1px solid $account-flyout__section-border-color;
	padding: 1rem 2.5rem 1rem 4rem;
}

.personal-info-wrapper {
	width: 100%;
	height: 6.8rem;
	display: block;
	margin-top: 1rem;

	&__image-wrapper {
		width: 6.8rem;
		display: inline-block;
		vertical-align: top;
		height: 6.8rem;
	}

	&__details {
		width: calc(100% - 6.8rem);
		display: inline-block;
		vertical-align: top;
		height: 6.8rem;
		padding: 0 1rem;
		-ms-word-break: break-all;
		overflow: hidden;
	}

	.avatarBig {
		height: 6.8rem;
		width: 6.8rem;
		border-radius: 50%;
	}
}

.details {
	&__wrapper {
		display: flex;
		justify-content: center;
		flex-direction: column;
		height: 6.8rem;
		width: 100%;

		&--name {
			font-size: 1.6rem;
			margin-bottom: 0.6rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-weight: 500;
		}

		&--email {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.account-actions-wrapper {
	display: block;
	width: 100%;
	margin-top: 2rem;

	ul {
		li {
			padding-bottom: 1.5rem;
			width: 100%;
			color: $account-flyout__link-color;
			cursor: pointer;

			.needs-underline-on-hover:hover {
				text-decoration: underline;
			}

			a {
				color: $account-flyout__link-color;
			}
		}
	}
}

.personal-settings {
	margin-top: 2.5rem;

	.margin-bottom-2 span {
		margin-bottom: 0.2rem !important;
	}
}

.personal-settings-wrapper {
	width: 100%;
	display: block;
	padding-bottom: 6rem;
	padding-right: 1.5rem;

	h3 {
		font-size: 1.5rem;
		color: $header-color;
		margin-bottom: 1.5rem;
		padding-bottom: 0.5rem;
		border-bottom: 1px solid #cecece;
	}

	span {
		font-size: 1.4rem;
		display: block;
		margin-bottom: 0.7rem;
	}
}

.remove-margin {
	margin: 0;

	button {
		font-size: 2.4rem;
	}
}
