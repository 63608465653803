.all-team-requests-wrapper {
    display: block;
    width: 100%;
    padding: 2rem 4rem;

    .all-team-requests-header {
        width: 100%;
        height: 5rem;
        display: flex;
        align-items: center;
        font-size: 2rem;
        font-weight: 500;
        margin: 0;
    }

    h2 {
        width: 100%;
        font-weight: 600;
        display: block;
    }
}
