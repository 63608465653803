
.mobile-menu {
    display: block;
    font-weight: 300;

    a {
        color: $mobile-menu__color;

        &:hover {
            text-decoration: none;
        }
    }

    background-color: $mobile-menu__background-color;
    color: $mobile-menu__color;
    font-size: 1.2rem;

    ul {
        padding: 0;
        margin: 0;

        li {
        
            display: block;

            .mobile-menu__menu-item-wrapper {
                display: flex;
                width: 100%;
                height: 5rem;
                justify-content: flex-start;
                border-bottom: .1rem solid $mobile-menu__border-color;
            }

            .mobile-menu__icon {
                display: flex;
                width: 5rem;
                height: 5rem;
                justify-content: center;
                align-items: center;
                font-size: 1.5rem;
            }

            .mobile-menu__text {
                display: flex;
                width: calc(100% - 30px);
                height: 5rem;
                align-items: center;
                margin-top: .1rem;
            }

        }

    }

}

@media only screen and (min-width: $breakpoint__medium) {  
    .mobile-menu {
        display: none;
    }
}