.team-modal-wrapper {
	display: block;
	min-width: 84%;
	max-width: 84%;
	max-height: 75%;
	background: white;
	border: 1px solid $team-wrapper-border;

	.scrollableContent-79 {
		overflow-y: hidden !important;
	}
	a {
		outline: 0;
	}
}

@media only screen and (min-width: $breakpoint__medium) {
	.team-modal-wrapper {
		min-width: 70.5rem;
		max-width: 70.5rem;
		min-height: 60rem;
		max-height: 60rem !important;
		overflow-y: auto;
	}
}
