.team-card-persona-wrapper {
	width: 2.4rem;
	height: 2.4rem;
	overflow: hidden;
	border-radius: 50%;

	&__photo {
		display: flex;
		width: 2.4rem;
		height: 2.4rem;
		justify-content: center;
		align-items: center;
		font-size: 1.8rem;
		overflow: hidden;
		border-radius: 50%;

		&-icon {
			display: flex;
			width: 2.4rem;
			height: 2.4rem;
			justify-content: center;
			align-items: center;
			font-size: 2.4rem;
			color: #aeaeae;
			border-radius: 50%;
			padding-top: 0.4rem;
		}
	}
	img {
		width: 2.4rem;
		height: 2.4rem;
		border-radius: 50%;
	}

	&__square {
		width: 4.8rem;
		height: 4.8rem;
		overflow: hidden;
	}
}

.member-big {
	width: 4.8rem;
	height: 4.8rem;
	border-radius: 50%;
}
