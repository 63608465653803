.team-card-wrapper {
  max-width: 32rem;
  min-width: 26rem;
  height: 19.1rem;
  border: 1px solid $team-wrapper-border;
  display: block;
  background: #fff;
  margin: 0 auto 4rem auto;
  overflow: hidden;

  &__header {
    height: 3rem;
    width: 100%;
    background-color: $primary-color;
    padding: 0;
    position: relative;

    &-photo {
      position: absolute;
      height: 4.8rem;
      width: 4.8rem;
      background: lightgray;
      top: 0.6rem;
      left: 1.5rem;
      cursor: pointer;
    }

    &-options-wrapper {
      float: right;
      width: 9rem;
      height: 100%;
    }
  }
}

.header-options {
  &__icon-group {
    display: inline-block;
    height: 100%;
    vertical-align: top;
    color: $team-card-icon-color;
    font-size: 1.3rem;
  }

  &__group-type {
    display: flex;
    flex: 0 0;
    width: 3rem;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &__favourite {
    display: flex;
    flex: 0 0;
    width: 3rem;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.no-members-spacer {
  width: 2.4rem;
  height: 2.4rem;
  display: inline-block;
}

.team-card-body {
  padding: 3.5rem 1.5rem 1.5rem;
  cursor: pointer;

  h1 {
    color: $font-color;
    font-size: 1.4rem;
    font-weight: $font-weight;
    letter-spacing: 0.05rem;
    margin-bottom: 0.5rem;
    max-height: 3rem;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  h2 {
    color: $font-color;
    font-size: 1.2rem;
    font-weight: $font-weight;
  }

  p {
    margin: 1rem 0 1.3rem;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .description {
    display: block;
    height: 2.9rem;
    overflow: hidden;
  }

  &__personas-wrapper {
    width: 100%;
    margin-top: 1.7rem;
    padding-bottom: 0.5rem;

    &-person {
      width: 2.4rem;
      height: 2.4rem;
      display: inline-block;
      background-color: lightgray;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      .img-placeholder {
        width: 2rem;
        height: 2rem;
        padding-top: 2px;
        align-self: flex-end;
      }
    }
    &-person:not(:first-child) {
      margin-left: 1rem;
    }
  }

  &__join-button-wrapper {
    display: flex;
    width: 100%;
    height: 2.1rem;
    justify-content: flex-end;
    align-items: flex-end;

    .ms-Button {
      padding: 0 1rem;
      height: 2.2rem;
      font-size: 1rem;
      float: right;
    }
  }
}

.external-user-warning {
  color: #c91616;
  font-size: bold !important;
  font-size: 1.2rem;
}

@media only screen and (min-width: 37rem) {
  .team-card-wrapper {
    min-width: 28rem;
  }
}

@media only screen and (min-width: $breakpoint__medium) {
  .team-card-wrapper {
    display: inline-block;
    min-width: 31.5rem;
    max-width: 31.5rem;
    vertical-align: top;
    margin: 2rem;
  }
}

.more-members {
  font-size: 1.6rem;
  display: inline-flex;
  color: #999;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  height: 2.8rem;
  letter-spacing: 0.5px;
}
