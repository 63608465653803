.summary {
  height: 11.9rem;
  background-color: #333;
  width: 100%;
  // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  // clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 100%);
  padding: 3.4rem;
  color: $summary-section__text-color;
  display: flex;

  &-wrapper {
    width: 100%;
    font-weight: 300;
  }

  & .user-wrapper {
    display: inline-block;
    width: 21rem;
    height: 5rem;
    vertical-align: top;

    &__photo {
      display: inline-block;

      &-avatar img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5rem;
        width: 5rem;
        border-radius: 50%;
      }
    }

    &__userInfo {
      display: inline-block;
      vertical-align: top;
      font-size: 1.3rem;
      overflow: hidden;
      -ms-word-break: break-all;
      word-break: break-all;

      /* Non standard for WebKit */
      word-break: break-word;

      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;

      &-text {
        display: flex;
        padding-left: 1.5rem;
        height: 5rem;
        width: 14.9rem;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
      }

      &-header {
        font-weight: 700;
        margin-bottom: 0.5rem;
        font-size: 1.3rem;
        height: 1.3rem;
        overflow: hidden;

        .blue-wrapper {
          color: $summary-section__header-color;
        }
      }

      &-sub {
        height: 1.3rem;
        overflow: hidden;
      }
    }
  }

  & .joined-teams-wrapper {
    display: none;
    vertical-align: top;
    width: 8rem;
    height: 5rem;
    border-left: 1px solid $summary-section__divider-color;
    font-size: 1.3rem;

    &__teams-info {
      display: flex;
      height: 5rem;
      width: 8rem;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding-left: 1.5rem;

      &-header {
        font-weight: 700;
        margin-bottom: 0.5rem;
        font-size: 1.3rem;

        .blue-wrapper {
          color: $summary-section__header-color;
        }
      }

      &-sub {
        font-size: 1.3rem;
      }
    }
  }

  .hideOnMedium {
    display: none;
  }
}

@media only screen and (min-width: 510px) {
  .summary {
    padding: 3.5rem;

    & .user-wrapper {
      width: 24rem;

      &__userInfo {
        &-text {
          width: 18.9rem;
        }
      }
    }

    & .joined-teams-wrapper {
      width: 16rem;
      display: inline-block;

      &__teams-info {
        width: 14rem;
      }
    }

    & .hideOnMedium {
      display: none;
    }
  }
}

@media only screen and (min-width: 882px) {
  .summary {
    & .hideOnMedium {
      display: inline-block;
    }
  }
}

.custom-alert {
  padding-left: 6rem;
  font-size: 1.4rem;
}

.alert {
  margin-bottom: 0px;
}
