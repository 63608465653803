.settings-tile {
	min-width: 31.5rem;
	max-width: 31.5rem;
	margin: 2rem 4rem 2rem 0rem;
	background: #fff;
	display: inline-block;
	border-top: 2px solid $team-wrapper-border;
	padding: 1.5rem;
	font-size: 1.2rem;
	height: 19.1rem;
	overflow-y: hidden;

	&-header {
		font-size: 1.4rem;
		font-weight: 500;
		margin-bottom: 1.5rem;
	}

	&-body {
		line-height: 1.8rem;
	}
	&:hover {
		background: $left-nav__link-background-color-hover;
		cursor: pointer;
	}
}

.setting-pages-wrapper {
	display: flex;
	flex-direction: column;
}

.settings-wrapper-half-page {
	min-width: calc(100%);
	max-width: calc(100%);
	margin: 2rem 4rem 2rem 0rem;
	background: #fff;
	display: inline-block;
	border: 1px solid $team-wrapper-border;
	padding: 4rem;
	font-size: 1.2rem;
	min-height: 20rem;
	overflow-y: hidden;

	&-header {
		font-size: 1.4rem;
		font-weight: 500;
		margin-bottom: 1.5rem;
	}

	&-body {
		line-height: 1.8rem;
	}
}

.settings-wrapper-full-page {
	min-width: 100%;
	max-width: 100%;
	margin: 2rem 4rem 2rem 0rem;
	background: #fff;
	display: inline-block;
	border: 1px solid $team-wrapper-border;
	padding: 4rem;
	font-size: 1.2rem;
	min-height: 20rem;
	overflow-y: hidden;

	&-header {
		font-size: 1.4rem;
		font-weight: 500;
		margin-bottom: 1.5rem;
	}

	&-body {
		line-height: 1.8rem;
	}
}

@media only screen and (min-width: $breakpoint__big) {
	.settings-wrapper-half-page {
		min-width: calc(33% - 2.4rem);
		max-width: calc(33% - 2.4rem);
	}

	.setting-pages-wrapper {
		flex-direction: row;
	}
}
