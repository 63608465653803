.team-header-wrapper {
	background: $border-light;
	width: 100%;
	height: 15.7rem;
	padding: 3rem;
}

.team-header {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	&__close-button {
		height: 1.4rem;
		width: 1.4rem;
		cursor: pointer;
	}
}

.content-wrapper {
	display: flex;
	width: 100%;
	height: 6.4rem;

	&__logo {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 6.4rem;
		width: 6.4rem;
	}

	&__application-bar a {
		display: inline-block;
		margin-bottom: 1rem;
	}

	.logo {
		height: 6.4rem;
		width: 6.4rem;
		background: lightgray;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 6.4rem;
			height: 6.4rem;
		}
	}

	&__title {
		display: flex;
		align-items: top;
		vertical-align: top;
		height: 6.4rem;
		width: calc(100% - 6.4rem);
		padding-left: 2rem;
		flex-direction: column;
		justify-content: center;

		h1 {
			color: $header-color;
		}
	}

	&__application-bar {
		width: 100%;
		display: block;
		height: 1.8rem;
		font-size: 1.8rem;
		margin-top: 1.3rem;

		i {
			margin-right: 1.5rem;
			cursor: pointer;
		}

		.outlook {
			color: #0072c6;
		}

		.calendar {
			color: #0072c6;
		}
		.onenote {
			color: #813a7c;
		}

		.files {
			color: #0072c6;
		}

		.sharepoint {
			color: #0072c6;
		}

		.teams {
			color: #5558af;
		}

		.teamsDisabled {
			color: #aeaeae;
			cursor: default;
		}
	}
}
