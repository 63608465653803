.account-panel {
    font-family: "Roboto", sans-serif;
}

.notification-hub-wrapper {
    width: 4.8rem;
    height: 4.8rem;
    display: inline-block;
    vertical-align: top;

    &__button {
        flex: 0 0 4.8rem;
        height: 4.8rem;
        width: 4.8rem;
        align-items: center;
        padding: 0;
        margin: 0;
        justify-content: center;
        border: 0;
        outline: none;
        background-color: $navbar__background-color;
        color: $navbar__text-color;
        font-size: 2rem;
        cursor: pointer;

        :hover,
        :active {
            outline: none;
        }

        svg {
            margin: auto;
            height: 4.8rem;
            font-size: 2.2rem;
            color: #fff;
        }

        &:hover {
            background-color: $navbar__office-icon-background-color--hover;
            text-decoration: none;
        }
    }

    &:active {
        outline: 0;
    }

    &:hover {
        outline: 0;
    }

    &:focus {
        outline: 0;
    }
}

.no-padding {
    padding: 0 !important;
}

.notification-wrapper {
    border-top: 1px solid $account-flyout__section-border-color;
    background: #fff;
}

.notifications-wrapper {
    &__content {
        margin-bottom: 3rem;
    }
}

.general-notification-hub-wrapper {
    padding: 3rem 4rem 3rem 4rem;
    background: $account-flyout__section-background-color;
    border-bottom: 1px solid #ccc;

    h3 {
        font-size: 1.5rem;
        color: $header-color;
        margin-bottom: 1.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #cecece;
    }

    span {
        font-size: 1.4rem;
        display: block;
        margin-bottom: 0.7rem;
    }

    .remove-margin {
        margin: 0 !important;
    }

    .notification-overview-wrapper {
    }
}
