.tabs-ul-wrapper {
	width: 100%;
	border-bottom: 1px solid lightgrey;

	li {
		display: block;
		padding: 0 2.5rem 1rem 2.5rem;
		border-bottom: 3px solid #fff;
		text-transform: uppercase;
		margin-bottom: 2rem;
		cursor: pointer;
	}

	li:hover {
		border-bottom: 3px solid $primary-color;
	}

	.active {
		border-bottom: 3px solid $primary-color;
	}
}

@media only screen and (min-width: $breakpoint__medium) {
	.tabs-ul-wrapper {
		li {
			display: inline-block;
			margin-bottom: 0;
		}
	}
}
