.navbar {
    &__container {
        display: flex;
        flex: 0 0 100%;
        height: 4.8rem;
        justify-content: space-between;
        background: $navbar__background-color;

        .active-class-right-menu {
            background: $navbar__office-icon-background-color--hover;
            color: $inverted-text-color !important;
        }

        ul.menu-right-side-content {
            li {
                height: 4.8rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 1.4rem;
                vertical-align: top;
                a {
                    display: inline-block;
                    font-weight: 500;
                    padding: 1.7rem 2.5rem 0 2.5rem;
                    color: $inverted-text-color !important;
                    cursor: pointer;
                    height: 100%;
                }
                a:hover {
                    text-decoration: none;
                }
            }

            li:hover {
                background: $navbar__office-icon-background-color--hover;
            }
        }
    }

    &__mobile-menu-button {
        display: inline-block !important;
        width: 4.8rem !important;
        min-width: 4.8rem !important;
        height: 4.8rem !important;
        background-color: $navbar__office-icon-background-color !important;
        padding: 0 !important;
        margin: 0 !important;
        cursor: pointer;

        &:hover {
            background-color: $navbar__office-icon-background-color--hover !important;
            text-decoration: none;
        }

        &__icon-wrapper {
            display: flex;
            width: 4.8rem;
            height: 4.8rem;
            align-items: center;
            justify-content: center;

            &-icon {
                font-size: 1.6rem;
                color: $navbar__text-color;
            }
        }
    }

    &__office-logo {
        display: none !important;
        width: 4.8rem !important;
        min-width: 4.8rem !important;
        height: 4.8rem !important;
        background-color: $navbar__office-icon-background-color !important;
        padding: 0 !important;
        margin: 0 !important;
        cursor: pointer;

        &:hover {
            background-color: $navbar__office-icon-background-color--hover !important;
            text-decoration: none;
        }

        &__icon-wrapper {
            display: flex;
            width: 4.8rem;
            height: 5rem;
            align-items: center;
            justify-content: center;

            &-icon {
                font-size: 2rem;
                color: $navbar__text-color;
            }
        }
    }
    &__indent-logo {
        margin-left: 1.5rem;
    }

    &__app-name {
        display: inline-block;
        vertical-align: top;

        &__wrapper {
            display: flex;
            flex: 0 0;
            margin-left: 0.8rem;
            align-items: center;
            width: 5.5rem;
            height: 4.8rem;

            font-size: 1.6rem;
            color: $navbar__text-color;
        }
    }

    &__companyLogo {
        height: 4.8rem;
        display: none;

        img {
            height: 4rem;
        }
    }

    &__companyLogo-anonymous {
        height: 4.8rem;
        display: none;
        margin-left: 14.6rem;

        img {
            height: 4rem;
        }
    }

    @media only screen and (min-width: $breakpoint__medium) {
        &__companyLogo {
            display: flex;
            align-items: center;
        }

        &__companyLogo-anonymous {
            display: flex;
            align-items: center;
        }

        &__mobile-menu-button {
            display: none !important;
        }

        &__office-logo {
            display: inline-block !important;
        }

        &__indent-logo {
            margin-left: 0;
        }
    }
}

.beta-logo {
    display: none;
    vertical-align: top;
    font-size: 10px;
    margin-top: -9px;
    margin-left: 5px;
    color: $title-subscript-color;
}
