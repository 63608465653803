.settings-wrapper {
    width: 4.8rem;
    height: 4.8rem;
    display: inline-block;
    vertical-align: top;

    &__button {
        flex: 0 0 4.8rem;
        height: 4.8rem;
        width: 4.8rem;
        align-items: center;
        padding: 0;
        margin: 0;
        justify-content: center;
        border: 0;
        outline: 0;
        background-color: $navbar__background-color;
        color: $navbar__text-color;
        font-size: 2rem;
        cursor: pointer;

        i {
            height: 4.8rem;
            width: 4.8rem;
            margin: auto;
            margin-top: 14px;
            font-size: 22px;
            display: block;
        }

        &:hover {
            background-color: $navbar__office-icon-background-color--hover;
            text-decoration: none;
        }
    }

    &:active {
        outline: 0;
    }

    &:hover {
        outline: 0;
    }

    &:focus {
        outline: 0;
    }
}

.no-padding {
    padding: 0 !important;
}

.settings-form-wrapper {
    padding: 3rem 4rem 3rem 4rem;
}

.general-settings-wrapper {
    padding: 3rem 4rem 3rem 4rem;
    background: $account-flyout__section-background-color;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid $account-flyout__section-border-color;

    & .settings-form-wrapper span {
        font-size: 1.4rem;
        display: block;
        margin-bottom: 0.7rem;
    }

    .form-input-group {
        width: 100%;
        display: block;
        margin: 0 0 3rem 0;
    }

    h3 {
        font-size: 1.5rem;
        color: $header-color;
        margin-bottom: 1.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #cecece;
    }

    h3 ~ h3 {
        margin-top: 3rem;
    }

    h3 ~ button {
        margin-top: 3rem;
    }

    input,
    select {
        width: 100%;
        display: block;
        padding: 0.4rem 0.8rem 0.6rem;
        font-size: 1.4rem;
    }
    .textarea {
        height: 7rem;
        padding: 0.4rem 0.8rem 0.6rem;
    }

    label {
        width: 100%;
    }

    label > span {
        font-size: 1.4rem;
        display: block;
        margin-bottom: 0.7rem;
    }

    input:focus,
    input:hover,
    select:focus,
    select:hover {
        outline-color: $primary-color;
    }

    .spacer-top {
        margin-top: 1.5rem;
    }

    .data-is-missing {
        color: red;
        font-weight: bold;
        display: inline-block;
    }

    .submit-button__margin {
        margin-bottom: 0.2rem !important;
    }
    .remove-margin {
        margin: 0 !important;
    }

    .input-feedback {
        color: #dc3545;
        font-style: italic;
        margin: 0.1rem 0 0.3rem;
        font-size: 1.2rem;
    }
}

.settings-panel,
.team-request-panel {
    h3 {
        font-size: 1.5rem;
        color: $header-color;
        margin-bottom: 1.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #cecece;
    }

    .margin-bottom-2 span {
        margin-bottom: 0.2rem !important;
    }

    button > span {
        font-size: 2.4rem;
    }
}

.custom-alert-settings {
    padding-left: 1.5rem;
    font-size: 1.4rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.custom-alert--move-left {
    margin-left: 3.6rem;
}
.top-nav {
    &__active-link {
        background-color: $navbar__office-icon-background-color--hover;
    }
}
