.metadata-content {
    background: $account-flyout__section-background-color;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid $account-flyout__section-border-color;
    padding: 3rem 4rem 3rem 4rem;

    .wrapper-spacer {
        margin-top: 1.5rem;
        margin-left: -0.5rem;
    }

    & span {
        font-size: 1.4rem;
        display: block;
        margin-bottom: 0.7rem;
    }

    .form-input-group {
        width: 100%;
        display: block;
        margin: 0 0 3rem 0;
    }

    h3 {
        font-size: 1.5rem;
        color: $header-color;
        margin-bottom: 1.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #cecece;
    }

    h3 ~ h3 {
        margin-top: 3rem;
    }

    h3 ~ button {
        margin-top: 3rem;
    }

    input,
    select {
        width: 100%;
        display: block;
        padding: 0.4rem 0.8rem 0.6rem;
        font-size: 1.4rem;
    }
    .textarea {
        height: 7rem;
        padding: 0.4rem 0.8rem 0.6rem;
    }

    label {
        width: 100%;
    }

    input:focus,
    input:hover,
    select:focus,
    select:hover {
        outline-color: $primary-color;
    }

    .spacer-top {
        margin-top: 1.5rem;
    }

    .data-is-missing {
        color: red;
        font-weight: bold;
        display: inline-block;
    }

    .submit-button__margin {
        margin-bottom: 0.2rem !important;
    }
}
