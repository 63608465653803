.requested-teams-list-wrapper {
    font-size: 1.4rem;

    &__text-wrapper {
        width: 60%;
        display: inline-block;
        cursor: pointer;
        color: $primary-color;
    }

    &__meta-wrapper {
        width: 40%;
        display: inline-block;
        text-align: right;

        &--green {
            color: #00bb00;
        }

        &--darkorange {
            color: darkorange;
        }

        &--red {
            color: red;
        }

        &--black {
            color: #333;
        }
    }

    &__item-details {
        width: 100%;
        background-color: #fafafa;
        margin: 1rem 0 2rem 0;
        border-top: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
        padding: 0 1rem;
    }
    &__row {
        padding: 1rem 0;

        &:first-of-type {
            padding: 1rem 0 0 0;
        }

        &-left {
            font-weight: 500;
        }

        &-left,
        &-right {
            vertical-align: top;
            width: 100%;
            display: inline-block;
            line-height: 1.8rem;
            overflow-wrap: break-word;
        }

        &--action-row {
            text-align: right;
            padding-top: 0;

            button {
                border: 1.5px solid #ccc;

                div {
                    font-weight: 500;
                }

                span {
                    margin: 0;
                }
            }
        }
    }

    li {
        margin-bottom: 1.5rem;

        .expand-icon-styles {
            font-size: 9px;
            color: #000 !important;
            font-weight: 900;
            display: inline;
            margin-right: 0.5rem;
        }
    }
}

.more-requests {
    margin-top: 4rem;

    a {
        color: $primary-color;
    }
}
